import React, { Component } from "react"
import Cookies from "universal-cookie"
import { navigate } from "gatsby"
// import {Link} from 'gatsby'
//import Success from "../pages/success"
//make years and days array

function generateArray(start, end) {
  let arr = []
  for (start; start <= end; start++) {
    arr.push(start)
  }
  return arr
}
// function ageError() {
//   return (
//     <div>
//       <span className="error">
//         Sorry you aren't allowed to enter this site.
//       </span>
//     </div>
//   )
// }
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
//1-31
const days = generateArray(1, 31)
//1900-today
const years = generateArray(1900, new Date().getFullYear()).reverse()

//calculate age
function calculateAge(birthday) {
  //milliseconds in a year 1000*24*60*60*365.24 = 31556736000;
  let birthArry = birthday.split(" ")
  if (
    birthArry[1] === "DD" ||
    birthArry[1] === "null" ||
    birthArry[0] === "MM" ||
    birthArry[0] === "null" ||
    birthArry[2] === "YYYY" ||
    birthArry[2] === "null"
  ) {
    return 0
  }
  let today = new Date(),
    //birthay has 'Dec 25 1998'
    dob = new Date(birthday),
    //difference in milliseconds
    diff = today.getTime() - dob.getTime(),
    //convert milliseconds into years
    years = Math.floor(diff / 31556736000),
    //1 day has 86400000 milliseconds
    days_diff = Math.floor((diff % 31556736000) / 86400000),
    //1 month has 30.4167 days
    months = Math.floor(days_diff / 30.4167),
    days = Math.floor(days_diff % 30.4167)

  console.log(months, days)

  return years
}

let ageCookie = new Cookies()

class AgeCalculator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      day: null,
      month: null,
      year: null,
      age: null,
      ageflag: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDayChange = this.handleDayChange.bind(this)
    this.handleMonthChange = this.handleMonthChange.bind(this)
    this.handleYearChange = this.handleYearChange.bind(this)
  }

  handleDayChange(e) {
    this.setState({
      day: e.target.value,
      ageflag: false,
    })
  }

  handleMonthChange(e) {
    this.setState({
      month: e.target.value,
      ageflag: false,
    })
  }

  handleYearChange(e) {
    this.setState({
      year: e.target.value,
      ageflag: false,
    })
  }

  handleSubmit(e) {
    e.preventDefault()

    const day = this.state.day,
      month = this.state.month,
      year = this.state.year

    let age = calculateAge(`${month} ${day} ${year}`)
    //age = age.replace(/ /, "/").replace(/ /, "/") //substitute - with /
    if (age >= 16) {
      // console.log("true")
      // if (window.OnetrustActiveGroups === ",1,2,") {
      ageCookie.set("isOldEnough", "yes", {
        path: "/",
        maxAge: 2592000,
        // secure: true,
        // httpOnly: true,
        // sameSite: true,
      })
      // }
      navigate("/snickers-hunger-insurance/")
      // this.props.setAgeGateShow(false)
      // this.props.setCountrySelection(true)
      //this.setState({ ageflag: true })
    } else if (
      day === "DD" ||
      day === null ||
      month === "MM" ||
      month === null ||
      year === "YYYY" ||
      year === null
    ) {
      // console.log("false")
      // this.props.setAgeGateShow(true)
      // this.props.setCountrySelection(false)
      this.setState({ ageflag: false })
      this.props.setIsOldError(true)
      this.props.setAgeError(false)
    } else {
      // this.props.setAgeGateShow(true)
      // this.props.setCountrySelection(false)
      this.setState({ ageflag: true })
      this.props.setIsOldError(false)
      this.props.setAgeError(false)
    }
  }

  render() {
    // function refreshPage() {
    //   // window.location.reload(false)
    //   // navigate("/")
    // }

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className="d-flex justify-content-center">
            <Input
              arr={months}
              handleChange={this.handleMonthChange}
              placeholder={"MM"}
              val={this.state.month}
            />
            <Input
              arr={days}
              handleChange={this.handleDayChange}
              placeholder={"DD"}
              val={this.state.day}
            />

            <Input
              arr={years}
              handleChange={this.handleYearChange}
              placeholder={"YYYY"}
              val={this.state.year}
            />
          </div>
          <div className="w-100 px-2">
            <input
              id="btn-agegate"
              className="btn btn-primary text-uppercase rounded-0"
              type="submit"
              value="Enter"
            />
          </div>
        </form>

        {this.state.ageflag && (
          <div>
            <span className="input-error">
              Below 16Years old are not allowed.
            </span>
          </div>
        )}
      </>
    )
  }
}

function Input(props) {
  let strt = [props.placeholder].concat(props.arr)
  let options = strt.map(item => (
    <option value={item} key={item}>
      {item}
    </option>
  ))

  return (
    <div className="mx-2">
      <select onChange={props.handleChange} value={props.val}>
        {options}
      </select>
      {/* <select onChange={props.handleChange} value={props.val}>
        {options}
      </select> */}
    </div>
  )
}

export default AgeCalculator
